import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Layout from '../../layout';
import archway from '../../assets/images/home/archway.svg';
import { QuizActionCreators } from '../../redux/actions/quiz';

const Home = () => {
  const dispatch = useDispatch();
  const { resetState } = bindActionCreators(
    QuizActionCreators,
    dispatch,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    resetState();
  }, []);

  return (
    <>
      <Layout>
        <div className="hero-background" />
        <div className="container welcome">
          <section className="col-12 py-5">
            <div className="row flex-md-row-reverse align-items-center g-md-5">
              <div className="col-12 col-md-5 pb-5 pb-md-0 text-center">
                <img
                  src={archway}
                  alt="Profusion"
                  className="g-0 mx-lg-auto img-fluid scale-2"
                  loading="lazy"
                />
              </div>
              <div className="col-12 col-md-7">
                <h1 className="mb-3 text-center text-md-start">
                  welcome to our
                  {' '}
                  <br />
                  {' '}
                  <span className="cormoorant-italic">exclusive dinner</span>
                  <br />
                  {' '}
                  with HR professionals
                </h1>
              </div>
            </div>
          </section>
          <section className="col-12 col-lg-8 offset-lg-2 py-md-5 mt-md-5 text-center">
            <div className="py-5 mt-5">
              <h2>Fancy a drink?</h2>
            </div>
            <div className="vline mx-auto" />
            <div className="py-5">
              <p>
                We&apos;ve developed an algorithm just for you, put your trust
                in data and let us recommend you a drink...
              </p>
            </div>
            <Link to="/quiz" className="btn btn-primary">Start</Link>
          </section>
        </div>
        <div className="footer-background" />
      </Layout>
    </>
  );
};

export default Home;
